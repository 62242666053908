import "../dist/styles.css";
import Waving from "../images/waving.png";
import Html from "../images/icons/html.svg";
import Css from "../images/icons/css3.svg";
import Js from "../images/icons/javascript.svg";
import React from "../images/icons/react.svg";
import Tailwind from "../images/icons/tailwind.svg";
import Docker from "../images/icons/docker.svg"
import Redux from "../images/icons/redux.svg";
import Express from "../images/icons/expressjs.svg";
import NodeJS from "../images/icons/nodejs.svg";
import MongoDB from "../images/icons/mongodb.svg";

function Hero() {
  const skillsIcons = [
    {
      img: Html,
      title: "HTML5",
    },
    {
      img: Css,
      title: "CSS3",
    },
    {
      img: Js,
      title: "Java Script",
    },
    {
      img: React,
      title: "React",
    }, {
      img: Redux,
      title: "Redux",
    },

    {
      img: Tailwind,
      title: "Tailwind CSS",
    },
    {
      img: Docker,
      title: "Docker",
    }, {
      img: Express,
      title: "Express JS",
    }, {
      img: NodeJS,
      title: "Node.JS",
    }, {
      img: MongoDB,
      title: "MongoDB",
    },
  ];

  return (
    <>
      <section id="home" className="hero">
        <div className="container">
          <div className="content">
            <div className="hero-main">
              <div className="hero-text">
                <h1>Full-Stack (MERN) Developer</h1>
                {/* <img src={Waving} alt="waving_hand" /> */}
                <p>
                  Hi, I'm Uttam Kr Shaw. A passionate Full-Stack
                  Developer based in Jharkhand, India. 📍
                </p>
                <span>
                  <a
                    aria-label="linkedin"
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.linkedin.com/in/uttam-kumar-aa183b126/"
                  >
                    <i className="fa-brands fa-linkedin"></i>
                  </a>
                  <a
                    aria-label="github"
                    rel="noreferrer"
                    target="_blank"
                    href="https://github.com/uttamkrshaw"
                  >
                    <i className="fa-brands fa-github"></i>
                  </a>
                  <a
                    href="https://drive.google.com/u/0/uc?id=1R0Gq3cFuy0t_2LlxlGQESS9mOV6Iehwn&export=download"
                    target="_blank"
                    download="Uttam_Kumar_Shaw_Resume.pdf"
                    rel="noreferrer"
                    id="resume-button-1"
                    class="nav-link resume"
                  >
                    <i class="fa-solid fa-download"></i>
                  </a>
                </span>
              </div>
              <div className="hero-img"></div>
            </div>

            {/*  */}
            <div className="skills">
              <p>Tech Stack</p>
              <div className="logos">
                <ul>
                  {skillsIcons.map((icon) => (
                    <li key={icon.title}>
                      <img src={icon.img} title={icon.title} alt="skill-icon" />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Hero;
