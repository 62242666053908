import ProBox from "./ProBox";
import purplle from "../images/purplle.png"
import sastabazar from "../images/sastabazar.png"

function Projects() {
  return (
    <>
      <section id="projects" className="project">
        <div className="container">
          <div className="project-content">
            <p>portfolio</p>
            <h3>Each project is a unique piece of development 🧩</h3>
            <div className="projects-grid">

              <ProBox
                title="Purplle Ecommerce"
                img={purplle}
                description="With a focus on simplicity and clean design, this store prioritize user experience, making it easy for customers to find and purchase the beauty products of they need."
                techno1="React"
                techno2="Express.JS"
                code="https://github.com/uttamkrshaw/dirty-parcel-1754-Purplle-"
                demo="https://clone-pruplle.netlify.app/"
                scrollY="-71%"
                icon="🛒"
                cName="reversed-proj"
              />

              <ProBox
                title="India Mart Ecommerce"
                img={sastabazar}
                description="With a focus on simplicity and clean design, this store prioritize user experience, making it easy for customers to find and purchase the products they need."
                techno1="React"
                techno2="Redux"
                code="https://github.com/uttamkrshaw/wandering-cactus-2429-India_Mart-"
                demo="https://clone-india-mart.netlify.app/"
                scrollY="-71%"
                icon="🛒"
                cName="reversed-proj"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Projects;
